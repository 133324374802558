import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const FooterInfo = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.customShadows.card,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> 剩蛋快樂 後台系統 </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
          <div style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              marginBottom: '20px'
            }}>
              <div style={{
                padding: '20px', // 内边距
                border: '5px solid transparent', // 边框设置
                borderImage: 'linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet) 1', // 边框渐变效果
                borderRadius: '15px', // 边框圆角
                animation: 'borderBlink 2s linear infinite', // 边框灯泡串动画
                boxShadow: '0 0 15px rgba(255, 0, 0, 0.5)', // 外层柔和发光效果
              }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center', // 文字居中
                    textShadow: '0 0 10px rgba(255, 255, 255, 0.8)', // 柔和的发光效果
                    animation: 'marquee 5s linear infinite', // 跑马灯效果
                    display: 'inline-block', // 保持文字宽度，确保动画效果
                    background: 'linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet)', // 彩虹颜色渐变
                    backgroundSize: '200% auto', // 背景尺寸
                    color: 'transparent', // 文字颜色透明
                    WebkitBackgroundClip: 'text', // 背景剪裁为文字
                  }}
                >
                  歡迎來到尊嘟假嘟工作室
                </Typography>
              </div>
            </div>

            <style>
            {`
              @keyframes marquee {
                0% {
                  background-position: 200% center; /* 起始位置 */
                }
                100% {
                  background-position: -200% center; /* 结束位置 */
                }
              }

              @keyframes borderBlink {
                0%, 100% {
                  border-image-source: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);
                }
                50% {
                  border-image-source: linear-gradient(90deg, blue, indigo, violet, red, orange, yellow, green);
                }
              }
            `}
            </style>


          <style>
          {`
            @keyframes marquee {
              0% {
                background-position: 200% center; /* 起始位置 */
              }
              100% {
                background-position: -200% center; /* 结束位置 */
              }
            }
          `}
          </style>
            <FooterInfo>
              <Typography variant="caption" color="textSecondary">
                sp 0927
              </Typography>
            </FooterInfo>
            {/* <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link variant="subtitle2">Get started</Link>
            </Typography>

            <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button>
            </Stack> */}

            {/* <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider> */}

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
